const QUERY_OPTIONS = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
    },
  },
};

export default QUERY_OPTIONS;
