import { ConfigProvider, App, theme } from "antd";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import NewsPage from "./routes/news/NewsPage";
import MainPage from "./routes/main/MainPage";
import QUERY_OPTIONS from "./config/query";

const queryClient = new QueryClient(QUERY_OPTIONS);

const router = createHashRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/vrcnews",
    element: <NewsPage />,
  },
]);

// const theme = { token: { colorPrimary: "#00b96b" } };
const themeDefinition = {
  algorithm: theme.darkAlgorithm,
};

function Website() {
  return (
    <ConfigProvider theme={themeDefinition}>
      <QueryClientProvider client={queryClient}>
        <App>
          <RouterProvider router={router} />
        </App>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default Website;
