import { Space, Card, Spin, Button, Badge } from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  ArrowRightOutlined,
  StarFilled,
  ExperimentFilled,
} from "@ant-design/icons";
import useGetVRCNews from "../../api/getVRCNews";
import Markdown from "react-markdown";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  discordEmotePattern,
  discordRolePattern,
} from "../../common/utils/regex";

import dayjs from "dayjs";
import "dayjs/locale/pl";
import styles from "./NewsPage.module.css";
import remarkGfm from "remark-gfm";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

dayjs.extend(relativeTime).locale("pl");

function NewsPageContent() {
  const query = useGetVRCNews();

  if (query.isError) {
    return (
      <div style={{ textAlign: "center" }}>
        Error! {(query.error as any)?.message}
      </div>
    );
  }
  if (query.isLoading) {
    return <Spin size="large" style={{ display: "block", margin: "0 auto" }} />;
  }
  if (!query.data) {
    return <>Error!</>;
  }

  return (
    <Space
      direction="vertical"
      size={24}
      className={styles["news-page-content"]}
      style={{
        margin: "0 auto",
        display: "flex",
        alignItems: "stretch",
        wordBreak: "break-word",
      }}
    >
      {query.data.messages.map((msg) => {
        let type: {
          text: React.ReactNode;
          textColor: string;
          color: string;
        } | null = null;
        if (msg.content.toLowerCase().includes("aktualizacja vrchat")) {
          type = {
            text: (
              <>
                <StarFilled /> Aktualizacja
              </>
            ),
            textColor: "black",
            color: "yellow",
          };
        } else if (msg.content.toLowerCase().includes("open beta")) {
          type = {
            text: (
              <>
                <ExperimentFilled /> Open beta
              </>
            ),
            textColor: "black",
            color: "green",
          };
        } else {
          type = {
            text: <>Inne</>,
            textColor: "black",
            color: "white",
          };
        }
        return (
          <article
            style={{
              display: type ? "block" : "none",
              maxWidth: "800px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            <Badge.Ribbon
              placement="start"
              text={
                type?.text ? (
                  <span
                    style={{
                      color: type?.textColor,
                      fontWeight: "bold",
                    }}
                  >
                    {type.text}
                  </span>
                ) : undefined
              }
              color={type?.color}
              style={{
                marginTop: "0.66rem",
              }}
            >
              <Card
                key={msg.id}
                extra={
                  <strong>
                    <span style={{ marginRight: "1.25rem" }}>
                      <UserOutlined style={{ marginRight: "0.15rem" }} />{" "}
                      {msg.author}
                    </span>
                    <time
                      dateTime={new Date(msg.createdTimestamp).toString()}
                      title={new Date(msg.createdTimestamp).toLocaleString()}
                    >
                      <ClockCircleOutlined style={{ marginRight: "0.15rem" }} />{" "}
                      {dayjs().to(dayjs(msg.createdTimestamp))}
                    </time>
                  </strong>
                }
                // extra={
                //   <p
                //     title={
                //       msg.editedTimestamp
                //         ? `Edytowano: ${new Date(
                //             msg.editedTimestamp
                //           ).toLocaleString()}`
                //         : undefined
                //     }
                //   >
                //     <em>
                //       {msg.editedTimestamp ? (
                //         <EditFilled style={{ marginRight: "0.25rem" }} />
                //       ) : (
                //         ""
                //       )}
                //       {new Date(msg.createdTimestamp).toLocaleString()}
                //     </em>
                //   </p>
                // }
                actions={[
                  // <EditFilled key="edit" />,
                  <a href={msg.url} target="_blank" rel="noreferrer">
                    Zobacz na Discordzie <ArrowRightOutlined />
                  </a>,
                ]}
              >
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    h1: "h2",
                    h2: "h3",
                    h3: "h4",
                    h4: "h5",
                    h5: "h5",
                  }}
                >
                  {msg.content
                    .replace(discordEmotePattern, "")
                    .replace(discordRolePattern, "")}
                </Markdown>
              </Card>
            </Badge.Ribbon>
          </article>
        );
      })}
      <div style={{ textAlign: "center", margin: "2rem 0" }}>
        <Button
          href={query.data.channel.url}
          type="default"
          shape="round"
          icon={undefined}
          size="large"
          style={{ textTransform: "uppercase", fontWeight: "bold" }}
        >
          Zobacz więcej
        </Button>
      </div>
    </Space>
  );
}

function NewsPage() {
  const META_TITLE = "VRowe Futra | Aktualności VRChat";
  const META_DESCRIPTION =
    "Wiadomości i aktualizacje o VRChat w języku polskim.";
  return (
    <main style={{ margin: "1rem" }}>
      <Helmet>
        <title>{META_TITLE}</title>
        <meta name="title" content={META_TITLE} />
        <meta name="description" content={META_DESCRIPTION} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vrowefutra.pl/#/vrcnews" />
        <meta property="og:title" content={META_TITLE} />
        <meta property="og:description" content={META_DESCRIPTION} />
        <meta property="og:image" content="https://vrowefutra.pl/banner.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://vrowefutra.pl/#/vrcnews"
        />
        <meta property="twitter:title" content={META_TITLE} />
        <meta property="twitter:description" content={META_DESCRIPTION} />
        <meta
          property="twitter:image"
          content="https://vrowefutra.pl/banner.jpg"
        />
      </Helmet>
      <Link
        to={{
          pathname: "/",
        }}
      >
        <img
          src="./logo.png"
          alt="VRowe Futra logo"
          style={{
            display: "block",
            width: "70px",
            margin: "0 auto 1rem auto",
          }}
        />
      </Link>
      <h1 style={{ textAlign: "center", marginBottom: "1rem" }}>
        Aktualności VRChat
      </h1>
      <div>
        <NewsPageContent />
      </div>
    </main>
  );
}

export default NewsPage;
