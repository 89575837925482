import { Button } from "antd";
import { Link } from "react-router-dom";
import { ReadFilled } from "@ant-design/icons";

import styles from "./MainPage.module.css";
import { Helmet } from "react-helmet";

const META_TITLE = "VRowe Futra";
const META_DESCRIPTION = "Społeczność polskich futrzaków w VRChat i VR";

function MainPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100svh",
        padding: "1rem",
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>{META_TITLE}</title>
        <meta name="title" content={META_TITLE} />
        <meta name="description" content={META_DESCRIPTION} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vrowefutra.pl/" />
        <meta property="og:title" content={META_TITLE} />
        <meta property="og:description" content={META_DESCRIPTION} />
        <meta property="og:image" content="https://vrowefutra.pl/banner.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://vrowefutra.pl/" />
        <meta property="twitter:title" content={META_TITLE} />
        <meta property="twitter:description" content={META_DESCRIPTION} />
        <meta
          property="twitter:image"
          content="https://vrowefutra.pl/banner.jpg"
        />
      </Helmet>

      <img src="./logo.png" alt="VRowe Futra logo" style={{ width: "150px" }} />
      <h1 style={{ fontSize: "3rem" }}>VRowe Futra</h1>
      <h2>Społeczność polskich futrzaków w VRChat i VR</h2>
      <h2 style={{ marginTop: "1.25rem", opacity: 0.5 }}>Strona w budowie</h2>

      <div className={styles["socials"]}>
        <a
          className={styles["social"]}
          href="https://vrowefutra.pl/discord"
          title="VRowe Futra Discord"
          target="_blank"
          rel="noreferrer"
          style={{ backgroundColor: "#7289da" }}
        >
          <img src="./socials/discord.webp" alt="Discord logo" />
        </a>
        <a
          className={styles["social"]}
          href="https://vrowefutra.pl/telegram"
          title="VRowe Futra Telegram"
          target="_blank"
          rel="noreferrer"
          style={{ backgroundColor: "#229ED9" }}
        >
          <img
            src="./socials/telegram.png"
            alt="Telegram logo"
            style={{ padding: "0.25rem 0.25rem 0 0" }}
          />
        </a>
        <a
          className={styles["social"]}
          href="https://vrowefutra.pl/vrchat"
          title="VRowe Futra grupa VRChat"
          target="_blank"
          rel="noreferrer"
          style={{ backgroundColor: "#fff" }}
        >
          <img
            src="./socials/vrchat.png"
            alt="VRChat logo"
            style={{ padding: "0.25rem" }}
          />
        </a>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <Link
          to={{
            pathname: "/vrcnews",
          }}
        >
          <Button
            size="large"
            type="default"
            shape="round"
            style={{
              textTransform: "uppercase",
              fontSize: "0.9rem",
            }}
          >
            <ReadFilled style={{ position: "relative", top: "1px" }} />
            Aktualności VRChat
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default MainPage;
