import { useQuery } from "react-query";
import { withApiUrlBase } from ".";
import axios from "axios";

type VRCNews = {
  channel: {
    id: string;
    name: string;
    url: string;
  };
  messages: {
    id: string;
    url: string;
    createdTimestamp: number;
    editedTimestamp?: number;
    author: string;
    content: string;
  }[];
};

function getVRCNews(): Promise<VRCNews> {
  return axios
    .get(withApiUrlBase("/vrcnews"))
    .then((response) => response.data);
}

function useGetVRCNews() {
  return useQuery("vrcnews", getVRCNews);
}

export default useGetVRCNews;
